.social-media-links {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 2rem;
    flex-direction: column;
}

.sm-icon-array {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.social-media-links a {
    color: var(--jelly-bean);
    padding: 5px;
    border-radius: 50%;
    border: 1px solid var(--jelly-bean);
    text-decoration: none;
    transition: color 0.3s, background-color 0.3s;
}

.social-media-links a:hover {
    color: white;
    background-color: var(--jelly-bean);
}

.sml-cotainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.bbs-img {
    width: 20px;
    height: 20px;
}

.bbs_container {
    display: flex;
    align-items: center;
}

.sm-bubble {
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 786px) {
    .social-media-links {
        flex-direction: column;
    }
    .sm-icon-array {
        display: flex;
        flex-direction: row;
        width: 100vw;
        align-items: center;
        justify-content: space-evenly;
    }
}