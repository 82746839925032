.intro-container {
    text-align: center;
}

.intro {
    margin-top: 0.5rem;
    font-weight: 500;
}

.night {
    color: var(--gallery);
}
