.experience-section {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
}

.experience-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 2rem;
    align-self: center;
}

@media (max-width: 786px) {
    .experience-grid {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
}
