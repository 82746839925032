.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 20px 20px;
    grid-auto-flow: row;
    grid-template-areas: "footer-job-opp footer-connect footer-gig-work" "footer-social-links footer-social-links footer-social-links";
    width: 75%;
    height: fit-content;
    font-size: large;
}

.footer-social-links {
    grid-area: footer-social-links;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer-job-opp {
    grid-area: footer-job-opp;
    padding: 5px;
}

.footer-connect {
    grid-area: footer-connect;
    padding: 5px;
}

.footer-gig-work {
    grid-area: footer-gig-work;
    padding: 5px;
}

.connect-description {
    height: inherit;
}

.copyright {
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    font-size: small;
}

@media (max-width: 786px) {
    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100vw;
    }
    .footer-container {
        display: flex;
        flex-direction: column;
    }
    .copyright {
        width: 100vw;
    }
}
