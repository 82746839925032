.project-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--jelly-bean);
}

.project-image-container {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid var(--jelly-bean);
    margin-top: 30px;
}

.project-details {
    text-align: center;
}

.project-details h3 {
    font-size: 18px;
    margin: 0.5rem;
    color: var(--jelly-bean);
}

.project-details p {
    font-size: 14px;
    color: #666;
}

.project-github_text {
    text-decoration: none;
    color: var(--tan);
    margin: 0.5rem;
}

.project-title_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
}

.project-grid-item svg {
    color: var(--thunder);
}

@media (max-width: 786px) {
    .project-grid {
        display: block;
    }
    .project-grid-item {
        margin: 20px;
    }
}