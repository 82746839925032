.skills-container.horizontal {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto auto;
    gap: 20px;
    align-self: center;
    padding: 1rem;
    width: 1000px;
}

.skills-grid-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.skills-column:nth-child(7) {
    justify-self: center;
}

.skills-column:nth-child(8) {
    grid-column: 3 / 3;
    justify-self: center;
}

@media (max-width: 786px) {
    .skills-container.horizontal {
        display: block;
    }
}