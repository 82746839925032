.download-cv-button {
    background-color: #f0f0f0;
    border: none;
    padding: 20px 20px;
    border-radius: 5px;
    cursor: pointer;
    color: var(--thunder);
    font-weight: 500;
    text-decoration: none;
    width: 150px;
    align-self: center;
    display: flex;
    justify-content: space-evenly;
    transition: color 0.3s, background-color 0.3s;
}

.download-cv-button:hover {
    background-color: var(--tan);
    color: white;
}

.button-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

@media (max-width: 786px) {
    .download-cv-button {
        justify-content: space-evenly;
        align-items: center;
    }
}