.coming-soon-overlay {
    border: 5px solid var(--jelly-bean);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 500px;
    height: 250px;
    color: var(--jelly-bean);
    background-color: white;
    border-radius: 10px;
    font-size: xx-large;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.construct-icon {
    padding: 5px;
}
