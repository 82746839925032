.work-experience-section {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.work-experience-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 2rem;
    align-self: center;
}

    .sun .experience-item .experience-item-grid .experience-item-title,.experience-item-details {
        color: var(--thunder);
    }

    .sun .work-experience-item .work-experience-item-title, .work-experience-item-details{
        color: var(--thunder);
    }

@media (max-width: 786px) {
    .work-experience-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90vw;
        margin: 1rem;
    }

}
