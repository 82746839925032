.projects-section {
    margin: 40px 2rem;
    display: flex;
    justify-content: center;
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Adjust the number of columns as needed */
    gap: 20px;
    width: 800px;
    align-self: center;
}

.project-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 5px;
    padding: 1rem;
    border: 2px solid var(--jelly-bean);
}
