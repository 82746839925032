.skills-column {
    border-radius: 5%;
    padding: 1rem;
    color: var(--jelly-bean);
    background-color: white;
    text-decoration: none;
    margin: 1rem auto;
    width: 250px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

ul {
    list-style-type: none;
    padding: 0;
}

.fa-icon {
    margin: 1rem 0.5rem 0.5rem 0.5rem;
}

.skills-column-description {
    font-size: medium;
    color: var(--thunder);
    margin-bottom: 10px;
}

.skills-toolList {
    list-style: none;
    padding: 0;
}

.skills-toolList li {
    color: var(--thunder);
    margin-bottom: 1rem;
}

.skill-list {
    color: var(--thunder);
    display: flex;
    justify-content: center;
}