:root {
    --ironstone: #874e3a;
    --tan: #cda07c;
    --gallery: #eeeeee;
    --jelly-bean: #227c96;
    --thunder: #353135;
    --westar: #e8e6e3;
    --cloud: #C8C3BC;
}

.App {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

body {
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    background-color: var(--gallery);
    font-weight: 500;
    color: var(--thunder);
}

.underconstruction {
    display: flex;
    align-items: center;
    justify-content: center;
}

.night {
    background-color: var(--thunder);
}

.night_mode_btn {
    position: absolute;
    right: 30px;
    top: 30px;
    border: none;
    background: none;
}

.rounded-img {
    align-self: center;
    border-radius: 50%;
    width: 300px;
    height: 300px;
    margin-top: 1rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.skills-header {
    padding-top: 2rem;
}

.button-section {
    width: auto;
}

.about-header {
    font-size: xx-large;
}

.sun {
    color: var(--gallery);
}

.moon {
    color: var(--thunder);
}

@media (min-width: 786px) {
    #about {
        width: 100vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .night_mode_btn {
        right: 30px;
        top: 30px;
    }
}

@media (orientation: landscape), (min-width: 768px) {
    .night_mode_btn {
        right: 75px;
        top: 75px;
    }
}

@media (min-width: 1025px) {
        .night_mode_btn {
        right: 500px;
        top: 100px;
    }
}
