header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 10px 0;
    width: 100vw;
}

header nav {
    display: flex;
}

.fa-header-icon {
    margin: 20px;
}

.header-container {
    display: flex;
    width: 1000px;
    justify-content: space-evenly;
    align-items: center;
}
.fa-header-icon {
    margin: 20px;
}

.nav-links .nav-night a {
    color: var(--cloud);
    transition: color 0.3s, background-color 0.3s;
}

.nav-links .nav-night a:hover {
    color: var(--thunder);
    background-color: var(--ironstone);
}

nav {
    display: flex;
    gap: 20px;
}

nav a {
    display: flex;
    text-decoration: none;
    color: var(--thunder);
    font-weight: 500;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
    transition: color 0.3s, background-color 0.3s;
}

nav a:hover {
    background-color: var(--tan);
    color: white;
}

.nav_title {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.fullscreen-menu {
    display: none; /* Hide by default */
}

@media (max-width: 786px) {
    header {
        width: 100vw;
    }
    .header-container {
        justify-content: flex-start;
    }
    .nav-links {
        display: none;
    }
    .nav_title {
        margin: 1rem;
    }
    .fullscreen-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }
    .fullscreen-nav-links {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .fullscreen-nav-links a {
        color: #fff;
        font-size: 2rem;
        margin: 1rem;
        text-decoration: none;
        cursor: pointer;
        transition: color 0.3s ease;
    }
    .fullscreen-nav-links a:hover {
        color: #ff6347;
    }
}
